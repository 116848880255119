<template>
    <div class="platform" :class="[{'platform--not-active' : notActive}, {'platform-empty': !active}]">
 
      <div class="platform__logo">
        <img v-if="slug === 'yandex'" class="small" src="@/assets/icons/yandex-direct-icon.svg" alt="platform-logo">
        <img v-else-if="slug === 'vkontakte'" class="small" src="@/assets/icons/icon-vkontacte.svg" alt="platform-logo">
        <img v-else-if="slug === 'facebook'" class="small" src="@/assets/icons/icon-meta.svg" alt="platform-logo">
        <img v-else-if="slug === 'google'" class="small" src="@/assets/icons/icon-google.svg" alt="platform-logo">
        <img v-else-if="slug === 'vkads'" class="small" src="@/assets/icons/icon-vkreklama.svg" alt="platform-logo">
        <img v-else-if="slug === 'mytarget'" class="small" src="@/assets/icons/icon-mytarget.svg" alt="platform-logo">
        <img v-else-if="slug === 'telegram'" class="small" src="@/assets/icons/icon-telegram.svg" alt="platform-logo">
        <img v-else-if="slug === 'tiktok'" class="small" src="@/assets/icons/icon-tiktok.svg" alt="platform-logo">
        <img v-else-if="slug === 'promopages'" class="small" src="@/assets/icons/icon-promo-page.jpeg" alt="platform-logo">
        <img v-else-if="slug === 'avito'" class="small" src="@/assets/icons/icon-avito.svg" alt="platform-logo">
        <img v-else-if="slug === 'vkmarket'" class="small" src="@/assets/icons/icon-vk-market.svg" alt="platform-logo">
        <img v-else-if="slug === 'metricapro'" class="small" src="@/assets/icons/icon-metrika-pro.svg" alt="platform-logo">
        <img v-else-if="slug === 'mediaplatform'" class="small" src="@/assets/icons/icon-media.png" alt="platform-logo">
        <img v-else-if="slug === 'yandexbusiness'" class="small" src="@/assets/icons/icon-yandex-business.png" alt="platform-logo">
          {{ active ? name: null }}
      </div>
    </div>
</template>

<script>
export default {
  name: 'PlatformCard',
  props: {
    name: {
      type: String,
      default: ''
    },
    slug:{
      type: String,
      default:''
    },
    commission: {
      type: String,
      default: ''
    },
    cashback: {
      type: String,
      default: ''
    },
    notActive: {
      type: Boolean,
      default: false
    },
    active : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      colors: {},
    }
  },
  created() {
    this.colors = this.$colors();
  },
  methods: {
    openOptions() {
      // this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddAccount' })
      // this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.platform {
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  max-height: 4.8rem;
  cursor: pointer;
  transition: 0.3s ease-out;
  position: relative;
  background: $primaryLight;
  box-shadow: 0px 5px 10px rgba(158, 158, 158, 0.1);
  padding: 1.5rem 1rem;

  &-empty {
    width: unset;
    justify-content: center;
    .platform__logo {
      justify-content: center;
    }
    img {
      margin: 0 !important;
    }
  }

  &--not-active {
    filter: grayscale(1);
    cursor: default;
    // .platform__logo {
    //   width: unset;
    //   img {
    //     margin: 0;
    //   }
    // }
  }

  &__logo {
    width: 50%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $text;

    img {
      margin-right: 1rem;
      width: 3rem;
      height: 3rem;
      object-fit: contain;
      display: block;

      &.small {
        height: 2.5rem;
      }
    }
  }

  &__info {
    width: 50%;
    font-size: 1.2rem;
    line-height: 1.4rem;

    &-item {
      display: flex;
      margin-bottom: .5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-title {
      color: #6C7285;
      margin-right: 0.6rem;
      width: 55%;
    }
  }

  &__options {
    position: absolute;
    right: 1.5rem;
    display: flex;
    flex-direction: column;

    span {
      width: 3px;
      height: 3px;
      display: flex;
      border-radius: 50%;
      background: #C4C4C4;
      margin-bottom: 4px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@include below(769px) {
  .platform{
    width: 100%;
    height: 40px;
  }
}
</style>
